





























































































/* eslint-disable */
import {useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, onBeforeMount, ref} from '@vue/composition-api'
import axios from "axios";
import {getApiUrl} from "@/inc/app.config";
import {logger} from "@/inc/utils";
import auth from "@/composables/auth";
import CharginTerminalsTable from "@/components/my/terminals/CharginTerminalsTable.vue";
import ChargingTerminalsLine from "@/components/my/terminals/ChargingTerminalsLine.vue";
import TerminalAddress from "@/components/my/terminals/TerminalAddress.vue";
import GLoader from "@/components/g/Loader.vue";

export default defineComponent({
  name: 'ChargingTerminals',
  components: {GLoader, TerminalAddress, ChargingTerminalsLine, CharginTerminalsTable},

  setup(_props, ctx) {
    const { $route } = ctx.root
    const { status } = $route.params

    const {user} = useState(['user'])
    const {i18n} = useState('my', ['i18n'])

    if(status) {
      const el = document.querySelector('#chargingTerminals')
      if (el) {
        el.scrollIntoView()
      }
    }

    const isLoad = ref(false)
    const isFindTerminals = ref(false)
    const errorLoadService = ref(false)
    const bornes = ref();
    const { currentLang } = useGetters(['currentLang'])
    const { $storage } = ctx.root
    const token = $storage.getItem('token', false)
    const ssoToken = ref('')

    const viewMessageDelete = ref(false)
    const viewMessageError = ref(false)

    ctx.root.$on('lineFromTab:delete', async (posTab, uuid)=> {

      isLoad.value = false;
      await axios
        .delete(`${getApiUrl()}/myresa/bornesDelete/${uuid}`, {
          data: { source: '' },
        })
        .then(resp => {
          const posLigne = bornes.value[posTab].bornes.findIndex(function(element) {
            return element.uuid === uuid;
          })
          bornes.value[posTab].bornes.splice(posLigne,1)

          if(bornes.value[posTab].bornes.length <= 0) bornes.value.splice(posTab,1)
          if(bornes.value.length <= 0) isFindTerminals.value = false;
          viewMessageDelete.value = true
          isLoad.value = true
        })
        .catch(e => {
          logger.trace('[terminal:Delete] FAILED')
          viewMessageError.value = true;
          isLoad.value = true
        })
      const el = document.querySelector('#chargingTerminals')
      if (el) {
        el.scrollIntoView()
      }
    })

    onBeforeMount (async () => {

      if(!auth.isLogged.value) {
        ctx.root.$router.push({
          name: 'MySignin'
        })
      }

      if(token && token.indexOf(' ') > 0) {
        ssoToken.value = token.split(' ')[1];
      }else {
        ssoToken.value = token;
      }

      await axios
        .get(`${getApiUrl()}/myresa/bornes`)
        .then(res => {
          bornes.value = res.data;

          if(res.data && res.data.length > 0) {
            isFindTerminals.value = true;
          }

          isLoad.value = true;
        })
        .catch(e => {
          isLoad.value = true;
          errorLoadService.value = true;
          logger.trace('[terminal:loadData] FAILED')
        })

    })

    return {
      i18n,
      user,
      isLogged: auth.isLogged,
      status,
      bornes,
      isLoad,
      isFindTerminals,
      errorLoadService,
      currentLang,
      apiDomain: process.env.VUE_APP_DOMAIN,
      ssoToken,
      viewMessageDelete,
      viewMessageError,
    }
  },
})
