

































































































/* eslint-disable */
import {useActions, useState} from '@u3u/vue-hooks'
import {defineComponent, ref} from '@vue/composition-api'
import {gsap} from "gsap";
import Terminal from "@/components/my/terminals/Terminal.vue";
import DeleteTerminal from "@/components/my/terminals/DeleteTerminal.vue";

export default defineComponent({
  name: 'ChargingTerminalsLine',
  components: {Terminal, DeleteTerminal},
  props:{terminals: Object, posTab: Number, posLigne:Number},

  setup(_props, ctx) {
    const {i18n} = useState('my', ['i18n'])

    const show = ref(false);
    const showModalDel = ref(false)

    const dateMED = ref('')
    const dateMHD = ref('')
    if (_props.terminals && _props.terminals.borne) {
      if(_props.terminals.borne.date) {
        const year = _props.terminals.borne.date.substring(0, 4)
        const month = _props.terminals.borne.date.substring(5, 7)
        const day = _props.terminals.borne.date.substring(8, 10)

        dateMED.value = `${day}/${month}/${year}`
      }

      if (_props.terminals.borne.dateActivation) {
        const year = _props.terminals.borne.dateActivation.substring(0, 4)
        const month = _props.terminals.borne.dateActivation.substring(5, 7)
        const day = _props.terminals.borne.dateActivation.substring(8, 10)

        dateMED.value = `${day}/${month}/${year}`
      }
      if (_props.terminals.borne.dateDesactivation) {
        const year = _props.terminals.borne.dateDesactivation.substring(0, 4)
        const month = _props.terminals.borne.dateDesactivation.substring(5, 7)
        const day = _props.terminals.borne.dateDesactivation.substring(8, 10)

        dateMHD.value = `${day}/${month}/${year}`
      }
    }

    const showHide =() => {
      if(_props.terminals) {
        show.value = !show.value;
        const el = document.querySelector(`#ligne_${_props.posTab}_${_props.posLigne}` )
        if (el) {
          el.scrollIntoView()
        }
      }
    }

    const onClickDeleteTerminal = data => {
      showModalDel.value = true
    }
    const closeModal = () => {
      showModalDel.value = false
    }
    ctx.root.$on('modal:confirm:close', () => {
      showModalDel.value = false
    })

    const textAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })
      },
    }

    return {
      i18n,
      show,
      showHide,
      textAnims,
      dateMED,
      dateMHD,
      showModalDel,
      onClickDeleteTerminal,
      DeleteTerminal,
      closeModal,
    }
  },
})
