var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-content__inner"},[_c('div',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.i18n.bornes.formBorneSup.formTitleDel)+" "+_vm._s(_vm.serial)+"? ")]),(!_vm.isLoading)?_c('div',{staticClass:"modal-content__button"},[_c('CustomLink',{staticClass:"cancel-delete",attrs:{"content":{
          label: _vm.i18n.bornes.formBorneSup.formButtonBack,
          icon: { name: 'arrows-small-left', size: '0 0 24 24' },
          iconPlacement: 'left',
        },"modifiers":['btn', 'white', 'orange', '', 'uppercase'],"type":'button'},on:{"btnClick":function($event){return _vm.no()}}}),_c('CustomLink',{staticClass:"confirm-delete",attrs:{"content":{
          label: _vm.i18n.bornes.formBorneSup.formButtonDel,
          icon: { name: 'arrows-small-right', size: '0 0 24 24' },
        },"modifiers":['btn', 'orange', 'white', '', 'uppercase'],"type":'button'},on:{"btnClick":function($event){return _vm.deleteTerminal()}}})],1):_c('g-loader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }