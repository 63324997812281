




































































import TerminalAddress from '@/components/my/terminals/TerminalAddress.vue'
import ChargingTerminalsLine from '@/components/my/terminals/ChargingTerminalsLine.vue'
import { defineComponent, ref } from '@vue/composition-api'
import GLoader from '@/components/g/Loader.vue'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'CharginTerminalsTable',
  components: { GLoader, TerminalAddress, ChargingTerminalsLine },
  props: { terminals: Object, posTab: Number },
  setup(_props) {
    const { i18n } = useState('my', ['i18n'])
    const isReload = ref(false)

    const filtre = ref('marque')
    const desc = ref(true)

    const filtreMarque = valeur => {
      filtre.value = valeur
      desc.value = !desc.value
    }

    const orderBy = arr =>
      // Set slice() to avoid to generate an infinite loop!
      arr.slice().sort((a, b) => {
        if (filtre.value === 'marque') {
          if (a.borne.marque < b.borne.marque) {
            if (desc.value) {
              return -1
            }

            return 1
          }
          if (a.borne.marque > b.borne.marque) {
            if (desc.value) {
              return 1
            }

            return -1
          }
        }
        if (filtre.value === 'modele') {
          if (a.borne.modele < b.borne.modele) {
            if (desc.value) {
              return -1
            }

            return 1
          }
          if (a.borne.modele > b.borne.modele) {
            if (desc.value) {
              return 1
            }

            return -1
          }
        }
        if (filtre.value === 'serial') {
          if (a.borne.serial < b.borne.serial) {
            if (desc.value) {
              return -1
            }

            return 1
          }
          if (a.borne.serial > b.borne.serial) {
            if (desc.value) {
              return 1
            }

            return -1
          }
        }
        if (filtre.value === 'statut') {
          if (a.borne.active < b.borne.active) {
            if (desc.value) {
              return -1
            }

            return 1
          }
          if (a.borne.active > b.borne.active) {
            if (desc.value) {
              return 1
            }

            return -1
          }
        }

        return 0
      })

    return {
      isReload,
      i18n,
      filtreMarque,
      orderBy,
    }
  },
})
