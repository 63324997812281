








































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import moment from 'moment/moment'

export default defineComponent({
  name: 'Terminal',
  props: { terminals: Object },
  setup(_props) {
    const { i18n } = useState('my', ['i18n'])

    const dateMED = ref('')
    const dateMHD = ref('')
    const photo = ref(false)

    if (_props.terminals && _props.terminals.borne) {
      if (_props.terminals.borne.date) {
        const year = _props.terminals.borne.date.substring(0, 4)
        const month = _props.terminals.borne.date.substring(5, 7)
        const day = _props.terminals.borne.date.substring(8, 10)

        dateMED.value = `${day}/${month}/${year}`
      }

      if (_props.terminals.borne.dateActivation) {
        const year = _props.terminals.borne.dateActivation.substring(0, 4)
        const month = _props.terminals.borne.dateActivation.substring(5, 7)
        const day = _props.terminals.borne.dateActivation.substring(8, 10)

        dateMED.value = `${day}/${month}/${year}`
      }
      if (_props.terminals.borne.dateDesactivation) {
        const year = _props.terminals.borne.dateDesactivation.substring(0, 4)
        const month = _props.terminals.borne.dateDesactivation.substring(5, 7)
        const day = _props.terminals.borne.dateDesactivation.substring(8, 10)

        dateMHD.value = `${day}/${month}/${year}`
      }

      if (
        _props.terminals.borne.photo &&
        _props.terminals.borne.photo.length > 0
      ) {
        photo.value = true
      }
    }

    return {
      i18n,
      photo,
      dateMED,
      dateMHD,
    }
  },
})
