



























































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
import axios from 'axios'
import CustomLink from '@/components/g/CustomLink.vue'
import Message from '@/components/ui/Message.vue'
import { logger } from '@/inc/utils'

export default defineComponent({
  name: 'delete-user',
  components: {
    CustomLink,
    Message,
  },
  props: {
    uuid: { type: String, required: true },
    serial: { type: String, required: true },
    posTab: { type: Number, required: true },
    parentInputStyle: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const isLoading = ref(false)
    const no = () => {
      ctx.root.$emit('modal:confirm:close')
      isLoading.value = false
    }

    const deleteTerminal = () => {
      ctx.root.$emit('modal:confirm:close')
      ctx.root.$emit('lineFromTab:delete', props.posTab, props.uuid)
    }

    return {
      deleteTerminal,
      no,
      i18n,
      isLoading,
    }
  },
})
